import React from "react"
import { Location } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from "../components/Main/Main"

const IndexPage = () => {
 return (
  <Location>
    {props => (
      <Layout {...props}>
        <SEO title="Home" />
        <Main {...props} />
      </Layout>
    )}
  </Location>
  );
}

export default IndexPage
